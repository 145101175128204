<template>
  <picture>
    <source :srcset="avifSrcSet" v-bind="avif" type="image/avif" />
    <source :srcset="webpSrcSet" v-bind="webp" type="image/webp" />
    <img v-bind="imgProps" />
  </picture>
</template>

<script setup lang="ts">
import type { SourceHTMLAttributes } from 'vue'

type Props = {
  avif?: SourceHTMLAttributes
  webp?: SourceHTMLAttributes
  fetchpriority?: 'low' | 'high'
  decoding?: 'async' | 'auto' | 'sync'
  width?: number | string
  height?: number | string
  src: string
  loading?: 'eager' | 'lazy'
  class?: string
  alt?: string
  preload?: boolean
  [key: string]: any
}

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<Props>(), {
  avif: undefined,
  webp: undefined,
  fetchpriority: 'low',
  width: '100%',
  height: '100%',
  loading: 'lazy',
  decoding: 'async',
  preload: false,
})

const IMG_INPUT_EXTENSION_REG = /(.png|.jpg|.jpeg)$/

const avifSrcSet = computed(() => getImagePath(props.src, 'avif'))
const webpSrcSet = computed(() => getImagePath(props.src, 'webp'))

const imgProps = computed(() => {
  const { avif, webp, ...imageProps } = props
  return { ...imageProps }
})

if (props.preload) {
  useHead({
    link: [
      {
        rel: 'preload',
        fetchpriority: 'high',
        as: 'image',
        href: webpSrcSet.value,
        type: 'image/webp',
      },
    ],
  })
}

function getImagePath(src: string, type: 'avif' | 'webp') {
  return src?.replace(IMG_INPUT_EXTENSION_REG, `.${type}`)
}
</script>
